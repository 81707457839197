<div id="programme" v-if="walksDetails.length > 0">
  <div class="yearprog">
    {#each walksDetails as walk}
      <button
        type="button"
        class:detailN={walk.details !== "Y"}
        class="detail"
        on:click={setHiWalk(walk)}
        title={toolTip(walk)}
      >
        <span class="date">{dispDat(walk.date)}</span>
        <span class="detail">{walk.area}</span>
      </button>
    {/each}
  </div>
  <div id="hiWalk" class="programme">
    <WalkHighlight walk={hiWalk} />
  </div>

  <div v-show="progPDF" class="screenonly progPDF">
    <a href={progPDF}>Download this programme as a PDF document</a>
  </div>
</div>

<script>
  import { onMount, beforeUpdate, afterUpdate } from "svelte";
  import { format } from "date-fns";
  import { getWalkData } from "$lib/WalksMixin.js";
  import WalkHighlight from "./_WalkHighlight.svelte";

  export let params;
  $: year = params.year;

  console.log("Years prog", year);
  let walksDetails = [];
  let hiWalk = {};
  let progPDF;
  let yearsWalks = async (year) => {
    let response = await getWalkData(`getYearsData`, year);
    let data = await response.json();
    console.log(data);
    let dat = format(new Date(), "yyyy-MM-dd");
    console.log({ dat });
    walksDetails = data?.walksDetails ?? [];
    hiWalk = data?.hiWalk;
    progPDF = data?.progPDF;
    return data;
  };
  let dispDat = (dat) => {
    return format(new Date(dat), "MMM d");
  };
  let toolTip = (walk) => {
    return `${walk.regname}, bus: ${walk.time}, org: ${walk.organizer}, maps: ${walk.map}`;
  };
  let setHiWalk = async (walk) => {
    console.log("showing", walk);
    let response = await getWalkData(`getWalkData`, walk.date);
    hiWalk = await response.json();
  };
  $: yearsWalks(year);
  // onMount(() => {
  //   yearsWalks(year);
  // });
  beforeUpdate(() => {
    console.log("BeforeUpadte;", $$props);
    // yearsWalks(year);
  });
  afterUpdate(() => {
    console.log("BeforeUpadte;", $$props);
    // yearsWalks(year);
  });
</script>

<style scoped>
  #programme {
    margin: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .yearprog {
    border: 3px solid #cc9999;
    padding: 6px;
    width: 400px;
    max-width: 100%;

    columns: 150px 2;
    column-rule: 3px solid #cc9999;
    font-size: x-small;
  }
  .progPDF {
    align-self: flex-start;
    padding-top: 0.5em;
  }
  .yearprog span {
    display: inline-block;
  }
  .yearprog .date {
    display: inline-block;
    width: 46px;
    padding-left: 3px;
  }
  .detail {
    color: #cc3300;
  }
  .detail:hover {
    color: white;
    background-color: #cc3300;
  }
  .detailN .detail {
    color: tan;
  }

  #hiWalk {
    padding: 6px;
    border: 3px solid #cc9999;
    border-top-width: 0;
    width: 320px;
  }

  @media only screen and (max-width: 338px) {
    .yearprog {
      width: auto;
    }
    #hiWalk {
      width: auto;
    }
  }
</style>
